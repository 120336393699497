<template>
  <div>
    <!-- Background Element start -->
    <!-- 1st Set -->
    <div
      class="mt-1 px-3 py-3 d-flex align-items-center justify-content-between"
      style="min-height: 50px; border-radius: 6px"
      :style="`background:${GreetBarDesigns.greetBarBGColor}`"
    >
      <span
        :style="`color:${GreetBarDesigns.greetBarTextColor};font-size:${GreetBarDesigns.fontSize}px;`"
        >{{ GreetBarDesigns.greetBarText }}</span
      >
      <button
        @click="triggerModal"
        class="btn px-3 py-2"
        :style="`background:${GreetBarDesigns.buttonColor};color:${GreetBarDesigns.buttonTextColor};border-radius:${GreetBarDesigns.borderRadius}px;font-size:${GreetBarDesigns.fontSize}px;`"
      >
        {{ GreetBarDesigns.buttonText }}
      </button>
    </div>

    <!-- Background Element end -->
    <StaticPopUp
      :isDesktopView="isDesktopView"
      :pageDesign="pageDesign"
      :PopUpDesigns="GreetBarDesigns"
    />
  </div>
</template>

<script>
import StaticPopUp from "./StaticPopUp.vue";
export default {
  props: ["GreetBarDesigns", "isDesktopView", "pageDesign"],
  components: { StaticPopUp },
  methods: {
    triggerModal() {
      let modal = document.getElementById("quizPopupModal");
      modal.style.display = "block";
    },
  },
};
</script>

<style></style>
